import { admin } from "../../ServiceConfig";

export function login(data) {
  return admin.post(`/login`, data);
}
export function loginWithGoogle(data) {
  return admin.post(`/login-by-google-credential`, data);
}

export function checkLogin() {
  return admin.get(`/check-login`);
}

export function logout() {
  return admin.post(`/logout`);
}

export function forgotPassword(user) {
  return admin.post(`/forget-password`, user);
}

export function resetPassword(user) {
  return admin.post(`/reset-password`, user);
}

export function checkResetPasswordToken(token) {
  return admin.post(`/check-token`, token);
}

export function checkPermission(permissionName) {
  return admin.get(`/employeeHasPermissionTo?permissionName=${permissionName}`);
}

export function getUpcomingBirthdays(){
  return admin.get(`/upcoming-birthdays`)
}
export function getUpcomingAnniversary(){
  return admin.get(`/upcoming-anniversaries`)
}
