import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { getGoogleReviewById } from "../../services/ListingSeoServices";

const ReviewCard = ({ data = null, id }) => {
  const [showData, setShowdata] = useState();

  const getReviewData = async (gid) => {
    try {
      const res = await getGoogleReviewById(gid);
      setShowdata(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setShowdata(data);
    } else if (id) {
      getReviewData(id);
    }
  }, [data, id]);
  return (
    <div className="w-full rounded-lg bg-white p-2 shadow-md transition-shadow duration-300 hover:shadow-lg">
      {/* Author Section */}
      <div className="flex justify-start gap-10">
        <div className="flex items-center">
          <img
            src={showData?.author_image_url}
            alt={showData?.author_name}
            className="h-12 w-12 rounded-full border-2 border-gray-300"
          />
          <div className="ml-3">
            <h3 className="text-lg font-semibold">{showData?.author_name}</h3>
            <p className="text-sm text-gray-500">
              {new Date(showData?.created_at).toLocaleDateString()}
            </p>
          </div>
        </div>

        {/* Rating Section */}
        <div className="mb-4 flex items-center">
          {/* Render stars dynamically */}
          {Array.from(
            { length: Math.round(showData?.review_rating) },
            (_, index) => (
              <FaStar key={index} className="mr-1 text-xl text-yellow-500" />
            )
          )}
          <span className="text-sm text-gray-500">
            ({showData?.review_rating} / 5)
          </span>
        </div>
      </div>

      {/* Review Content */}
      <p className="text-xs text-gray-700">
        {showData?.review_text?.split(" ").slice(0, 25).join(" ")}
        {showData?.review_text?.split(" ").length > 25 && "..."}
      </p>

      {/* Review Link */}
      {showData?.google_review_link && (
        <a
          href={showData?.google_review_link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-500 underline"
        >
          View on Google Maps
        </a>
      )}
    </div>
  );
};

export default ReviewCard;
