import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import {
  getAdminUrl,
  getFrontUrl,
  getUploadsUrl,
} from "../../../ServiceConfig";
import {
  FaCheckSquare,
  FaCog,
  FaDownload,
  FaEdit,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilter,
  FaPlusSquare,
  FaTimes,
} from "react-icons/fa";
import ComponentHeader from "../common/ComponentHeader";
import H2 from "../../../components/headings/H2";
import {
  bulkPackageDataUpdate,
  deletePackage,
} from "../../services/PackageService";
import InputLabel from "../inputs/InputLabel";
import SelectSearch from "../inputs/SelectSearch";
import { getAllEmployeesOption } from "../../services/EmployeeServices";
import { getAllCountriesOption } from "../../services/CountryServices";
import { getAllCategories } from "../../services/CategoryServices";
import { getAllStateByCountry } from "../../services/StateServices";
import useDataTable from "../../hooks/useDataTable";
import OutsideClick from "../../../hooks/OutsideClick";
import useOutsideClick from "../../../hooks/useOutsideClick";

function PackageList() {
  const navigate = useNavigate();
  const packageTable = useRef();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [bulkActionOpen, setBulkActionOpen] = useState(false);
  const [columnVisibilityOpen, setColumnVisibilityOpen] = useState(false);
  const [downloadOptionOpen, setDownloadOptionOpen] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState();

  const filterRef = useRef(null);
  const filterRef1 = useRef(null);
  const bulkActionRef = useRef(null);
  const bulkActionRef1 = useRef(null);

  const deleteSelectedPackage = (id) => {
    if (!window.confirm("Do You really want to delete this package?")) return;
    deletePackage(id)
      .then((res) => {
        toast.success(res.data.message);
        packageTable.current.deleteRow(id);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const [employees, setEmployees] = useState([]);
  const [countries, setCountries] = useState();
  const [selectedCountryStates, setSelectedCountryStates] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        filterRef1.current &&
        !filterRef1.current.contains(event.target)
      ) {
        setFilterOpen(false);
      }
      if (
        bulkActionRef.current &&
        !bulkActionRef.current.contains(event.target) &&
        bulkActionRef1.current &&
        !bulkActionRef1.current.contains(event.target)
      ) {
        setBulkActionOpen(false);
      }
    }
    // ? Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //? Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef, filterRef1, bulkActionRef, bulkActionRef1]);

  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCountriesOption()
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err, "Error---------------");
      });

    getAllCategories()
      .then((res) => {
        setCategories(res.data.data.data);
      })
      .catch((err) => {
        console.log(err, "Error---------------");
      });

    packageTable.current.setFilter(selectedFilter);
    // console.log(packageTable.current?.getColumns());
  }, [selectedFilter]);

  // ?--------------- Fetch State By CountryId ---------------
  const getStatesByCountry = (countryId) => {
    getAllStateByCountry(countryId)
      .then((res) => {
        setSelectedCountryStates(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const onDataSelection = (data) => {
    // console.log(data);
    setSelectedData(data);
  };

  const updateBulkPackages = (data) => {
    let postdata = {};
    postdata[data.field] = data.value;
    postdata["ids"] = selectedData.map((a) => a.id);
    if (postdata["ids"].length > 0) {
      bulkPackageDataUpdate(postdata)
        .then((res) => {
          toast.success("Updated Successfully");
          packageTable.current.updateTable();
        })
        .catch((err) => {
          console.error("Error updating packages:", err);
          toast.error("Failed to update packages");
        });
    }

    console.log(data, "data......");
    console.log(selectedData, "Selected data......");
    console.log(postdata, "post data......");
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
   <div class="flex items-center gap-3">
      <div class="package-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="package-delete-btn cursor-pointer text-red-500" data-id=${
        cell.getRow().getData().id
      }>
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const isFrozen = window.innerWidth <= 768 ? false : true;

  const columns = [
    {
      formatter: "rowSelection",
      minWidth: "40",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect();
      },
      frozen: isFrozen,
    },
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",

      minWidth: 50,
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      frozen: isFrozen,
    },
    {
      title: "Title",
      field: "title",
      minWidth: "250",
      vertAlign: "middle",

      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
      frozen: isFrozen,
    },
    {
      title: "Url",
      field: "url",
      minWidth: "90",
      vertAlign: "middle",
      hozAlign: "center",

      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getValue() != "" &&
          cell.getValue() != null &&
          cell.getValue() != undefined
        ) {
          return `
          <div class="center">
          <a href="${getFrontUrl()}${cell.getValue()}" target="_blank" class="rounded-sm py-2 px-4 bg-blue-500 hover:bg-blue-700 text-center text-white">View</a>
          </div>
          `;
        } else {
          return '<div class="center text-center"><i class="fa fa-times" aria-hidden="true" style="color: red;"></i></div>';
        }
      },
    },
    {
      title: "Slug",
      field: "slug",
      minWidth: "250",
      vertAlign: "middle",

      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
      //  formatter: function (cell, formatterParams, onRendered) {
      //   if (cell.getValue() === "1") {
      //     return "Customized";
      //   } else {
      //     return "FD";
      //   }
      // },
      // frozen: isFrozen,
    },
    {
      title: "Category",
      field: "category",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: 150,
    },
    {
      title: "Type",
      field: "package_type",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === "1") {
          return "Customized";
        } else {
          return "FD";
        }
      },
      minWidth: 100,
    },
    {
      title: "Assigned To",
      field: "assigned_to_full_name",
      minWidth: "200",
      hozAlign: "center",
      vertAlign: "middle",

      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
      // frozen: isFrozen,
    },
    {
      title: "Country",
      minWidth: 150,
      field: "country",
      hozAlign: "center",
      resizable: true,
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "Status",
      field: "status",
      hozAlign: "center",
      vertAlign: "middle",
      resizable: true,

      formatter: "tickCross",
      minWidth: "100",
    },
    // {
    //   title: "Banner Image",
    //   hozAlign: "center",
    //   vertAlign: "middle",
    //   headerSort: false,
    //   field: "banner_image.image",
    //   formatter: "image",
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     urlPrefix: `${getUploadsUrl()}/package/images/banner/original/`,
    //     urlSuffix: "",
    //   },
    // },
    {
      title: "State",
      field: "state",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "Location",
      field: "location",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      formatter: "textarea",
    },
    {
      title: "Recommended",
      field: "is_recommended",
      minWidth: "120",
      resizable: false,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "BestSeller",
      field: "is_bestseller",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "110",
    },
    {
      title: "Christmas",
      field: "is_xmas_newyear",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "110",
    },
    {
      title: "Trending",
      field: "is_trending",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "100",
    },
    {
      title: "Trending",
      field: "is_trending",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "100",
    },
    {
      title: "Summer Trips",
      field: "is_summer_trip",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "130",
    },
    {
      title: "Winter Trips",
      field: "is_winter_trip",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "130",
    },
    {
      title: "Monsoon Trips",
      field: "is_monsoon_trip",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      formatter: "tickCross",
      minWidth: "130",
    },
    // {
    //   title: "Upcoming Batches",
    //   field: "upcoming_batches",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    //   hozAlign: "center",
    //   formatter: "tickCross",
    //   minWidth: "130",
    // },
    // {
    //   title: "Missing Gallery Images",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    //   hozAlign: "center",
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if(cell.getData().images.length >= 5){
    //       return "No"
    //     }
    //     else{
    //       return "Yes"
    //     }
    //   },
    //   minWidth: "130",
    // },
    // {
    //   title: "Missing Banner Images",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    //   hozAlign: "center",
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if(cell.getData().banner_image.length >= 8){
    //       return "No"
    //     }
    //     else{
    //       return "Yes"
    //     }
    //   },
    //   minWidth: "130",
    // },
    {
      title: "Pdf Itinerary",
      field: "pdf_itinerary",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",

      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getValue() != "" &&
          cell.getValue() != null &&
          cell.getValue() != undefined
        ) {
          return `
          <div class="center">
          <a href="${getUploadsUrl()}/package/pdf/${cell.getValue()}" target="_blank" class="rounded-sm py-2 px-4 bg-blue-500 hover:bg-blue-700 text-center text-white">View</a>
          </div>
          `;
        } else {
          return '<div class="center text-center"><i class="fa fa-times" aria-hidden="true" style="color: red;"></i></div>';
        }
      },
      minWidth: "120",
    },

    // {
    //   title: "Price",
    //   field: "price",
    //   resizable: true,
    //   headerSort: false,
    //   vertAlign: "middle",
    // },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,

      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];

  useEffect(() => {
    $(document).on("click", ".package-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/package/edit/${cell}`);
    });

    $(document).on("click", ".package-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      deleteSelectedPackage(dataId);
    });

    return () => {
      $(document).off("click", ".package-edit-btn");
      $(document).off("click", ".package-delete-btn");
    };
  });

  return (
    <div className="flex h-full flex-col dark:bg-slate-900 dark:text-white">
      <ComponentHeader className="border-b px-0 lg:px-4">
        <Link to="/package">
          <H2>Packages</H2>
        </Link>
        <Link
          to="/package/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="mb-2 h-full">
        {/* {console.log(selectedData, "selectedData")} */}

        {selectedData?.length > 0 && (
          <div ref={bulkActionRef1} className="mr-2 inline-block pt-4">
            <button
              onClick={() => setBulkActionOpen(!bulkActionOpen)}
              className={`text-md flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-8 py-2 font-semibold tracking-wide dark:bg-slate-800 ${
                bulkActionOpen
                  ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                  : ""
              }`}
            >
              <FaEdit />
              Bulk Action
            </button>
          </div>
        )}
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-2 inline-block pt-4"
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={`text-md flex items-center gap-1 whitespace-nowrap rounded-md border bg-white  p-8 py-2 font-semibold tracking-wide dark:bg-slate-800 ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-8 pb-4 pt-2"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>

        <div className="relative inline-block">
          <OutsideClick onClick={() => setColumnVisibilityOpen(false)}>
            <button
              onClick={() => {
                setColumnVisibilityOpen(!columnVisibilityOpen);
                setRenderedColumns(packageTable.current.getColumns());
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white  p-8 py-2 font-semibold tracking-wide dark:bg-slate-800 `}
            >
              <FaCog /> Column Visibility
            </button>

            <div
              className={`${
                columnVisibilityOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white  py-0 dark:bg-slate-800`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                {renderedColumns &&
                  renderedColumns.map((column, key) => (
                    <div key={key}>
                      {column._column.definition.title && (
                        <label htmlFor={`id${key}`} className={`m-0 !h-0 p-0`}>
                          <li
                            className={`${
                              column.isVisible()
                                ? "bg-white text-gray-900 dark:bg-slate-800 dark:text-white"
                                : "bg-gray-200 text-gray-900 dark:bg-slate-700 dark:text-white"
                            } text-md cursor-pointer items-center border-y border-slate-100 py-2 px-4 text-justify  align-middle font-bold`}
                          >
                            <div className="flex items-center justify-between">
                              <p className="inline-block">
                                {column._column.definition.title}
                              </p>
                              <input
                                id={`id${key}`}
                                type="checkbox"
                                value="1"
                                checked={column.isVisible()}
                                onChange={(e) => {
                                  column.toggle();
                                  setRenderedColumns(
                                    packageTable.current.getColumns()
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="right-1 inline-block">
                                {column.isVisible() ? (
                                  <div className="text-green-700">
                                    <FaCheckSquare />
                                  </div>
                                ) : (
                                  <div className="text-red-700">
                                    <FaTimes />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </label>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </OutsideClick>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setDownloadOptionOpen(false)}>
            <button
              onClick={() => {
                setDownloadOptionOpen(!downloadOptionOpen);
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white  p-8 py-2 font-semibold tracking-wide dark:bg-slate-800 `}
            >
              <FaDownload />
              Download
            </button>

            <div
              className={`${
                downloadOptionOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0 dark:bg-slate-800`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                <div key="csv">
                  <li
                    onClick={() =>
                      packageTable.current.download("csv", `Data.csv`, {
                        delimiter: ",",
                      })
                    }
                    className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">CSV</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileCsv />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="xlsx">
                  <li
                    onClick={() =>
                      packageTable.current.download("xlsx", `Data.xlsx`, {
                        sheetName: "sheet1",
                      })
                    }
                    className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">XLSX</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileExcel />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="pdf">
                  <li
                    onClick={() =>
                      packageTable.current.download("pdf", `Data.pdf`, {
                        orientation: "portrait",
                        title: "Package List",
                      })
                    }
                    className={`text-gray-900text-md items-center border-y border-slate-100 bg-white py-2 px-4 text-justify align-middle  font-bold dark:bg-slate-800`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">PDF</p>

                      <div className="right-1 inline-block">
                        <div className="text-red-500">
                          <FaFilePdf />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </OutsideClick>
        </div>

        {/* BulK action Starts here */}
        {selectedData?.length > 0 && (
          <div
            ref={bulkActionRef}
            className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white   dark:bg-slate-800 ${
              bulkActionOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
          >
            <div className="min-w-[250px]">
              <InputLabel label="Assigned To" />
              <SelectSearch
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "assigned_to",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Category" />
              <SelectSearch
                options={categories?.map((category) => ({
                  label: `${category?.name}`,
                  value: category?.id,
                }))}
                onChange={(value) => {
                  updateBulkPackages({
                    field: "category",

                    value: value,
                  });
                }}
              />
            </div>

            <div className="min-w-[250px]">
              <InputLabel label="Is Active ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "status",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is Trending" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_trending",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is BestSeller ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_bestseller",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is Recommended ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_recommended",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is Summer Trip ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_summer_trip",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is Monsoon Trip ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_monsoon_trip",

                    value: value,
                  })
                }
              />
            </div>
            <div className="min-w-[250px]">
              <InputLabel label="Is Winter Trip ?" />
              <SelectSearch
                options={[
                  { name: "Yes", id: 1 },
                  { name: "No", id: 0 },
                ]?.map((item) => ({
                  label: `${item?.name}`,
                  value: item?.id,
                }))}
                onChange={(value) =>
                  updateBulkPackages({
                    field: "is_winter_trip",

                    value: value,
                  })
                }
              />
            </div>
          </div>
        )}
        {/* BulK action Ends here */}

        {/* filter Code starts Here */}

        <div
          ref={filterRef}
          className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white  dark:bg-slate-800 ${
            filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div className="min-w-[250px]">
            <InputLabel label="Assigned To" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "assigned_to")
                  ?.value
              }
              options={employees?.map((emp) => ({
                label: `${emp?.first_name} ${emp?.last_name}`,
                value: emp?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "assigned_to",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Category" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "category")
                  ?.value
              }
              options={categories?.map((category) => ({
                label: `${category?.name}`,
                value: category?.id,
              }))}
              onChange={(value) => {
                updateFilter({
                  field: "category",
                  type: "=",
                  value: value,
                });
              }}
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Country" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "country")
                  ?.value
              }
              options={countries?.map((country) => ({
                label: `${country?.name}`,
                value: country?.id,
              }))}
              onChange={(value) => {
                getStatesByCountry(value);
                updateFilter({
                  field: "country",
                  type: "=",
                  value: value,
                });
              }}
            />
          </div>
          {selectedCountryStates?.length > 0 && (
            <div className="min-w-[250px]">
              <InputLabel label="State" className="dark:text-white" />
              <SelectSearch
                value={
                  selectedFilter.find((filter) => filter.field === "state")
                    ?.value
                }
                options={selectedCountryStates?.map((state) => ({
                  label: `${state?.name}`,
                  value: state?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "state",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
          )}

          <div className="min-w-[250px]">
            <InputLabel label="Is Active ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "status")
                  ?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "status",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is Trending" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find((filter) => filter.field === "is_trending")
                  ?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_trending",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is BestSeller ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_bestseller"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_bestseller",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is Recommended ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_recommended"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_recommended",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is Summer Trip ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_summer_trip"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_summer_trip",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is Monsoon Trip ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_monsoon_trip"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_monsoon_trip",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel label="Is Winter Trip ?" className="dark:text-white" />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_winter_trip"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_winter_trip",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel
              label="Is Christmas Trip ?"
              className="dark:text-white"
            />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "is_xmas_newyear"
                )?.value
              }
              options={[
                { name: "Yes", id: 1 },
                { name: "No", id: 0 },
              ]?.map((item) => ({
                label: `${item?.name}`,
                value: item?.id,
              }))}
              onChange={(value) =>
                updateFilter({
                  field: "is_xmas_newyear",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel
              label="Is Upcoming Trip ?"
              className="dark:text-white"
            />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "upcoming_batches"
                )?.value
              }
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: "0" },
              ]}
              onChange={(value) =>
                updateFilter({
                  field: "upcoming_batches",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel
              label="Missing Banner Images?"
              className="dark:text-white"
            />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "banner_images"
                )?.value
              }
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: "0" },
              ]}
              onChange={(value) =>
                updateFilter({
                  field: "banner_images",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div className="min-w-[250px]">
            <InputLabel
              label="Missing Gallery Images?"
              className="dark:text-white"
            />
            <SelectSearch
              value={
                selectedFilter.find(
                  (filter) => filter.field === "gallery_images"
                )?.value
              }
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: "0" },
              ]}
              onChange={(value) =>
                updateFilter({
                  field: "gallery_images",
                  type: "=",
                  value: value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Title" className="dark:text-white" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "title",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="By Package Id" className="dark:text-white" />
            <input
              type="number"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "id",
                  type: "=",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="package-table h-full">
        <DataTable
          ref={packageTable}
          ajaxUrl={`${getAdminUrl()}/packages`}
          columns={columns}
          onDataSelection={onDataSelection}
          search={false}
          // responsiveLayout="collapse"
        />
      </div>
    </div>
  );
}

export default PackageList;
