import { useEffect, useState } from "react";
import { getUpcomingAnniversary } from "../../services/AdminServices";
import { getUploadsUrl } from "../../../ServiceConfig";
import moment from "moment";

function UpComingAnnivesary() {
  const [upcomingAnniversary, setUpcomingAnniversary] = useState();

  const getUpcomingAnn = async () => {
    const result = await getUpcomingAnniversary();
    if (result.data.status === "success") {
      setUpcomingAnniversary(result.data.data);
    }
  };
  useEffect(() => {
    getUpcomingAnn();
  }, []);

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/upcoming-anniversary.png`}
            className="absolute bottom-1 left-1 h-16 w-16"
            alt=""
          />
          <h3 className="ml-12 font-bold">Upcoming Work Anniversary</h3>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex flex-col items-center justify-center gap-3 py-4">
            {upcomingAnniversary?.map((anniversary, index) => (
              <div
                key={anniversary.id}
                className={`flex w-full items-center justify-between gap-4 rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
              >
                <div className="h-12 w-12 shrink-0 rounded-full bg-gray-300">
                  <img
                    src={`${getUploadsUrl()}/employees/${
                      anniversary?.profile_pic || "png-transparent-user.png"
                    }`}
                    alt="profile pic"
                    className="h-12 w-12 rounded-full"
                  />
                </div>
                <div className="line-clamp-1 grow font-medium">
                  <p className="text-sm pr-1 relative text-gray-800 dark:text-white flex gap-1 items-center justify-between">
                  <span>  {anniversary?.first_name +        "  " +           anniversary?.last_name }
                  <br/>
                  <span className="text-[12px] leading-none" >{anniversary?.designation}</span>{anniversary?.designation && anniversary?.department?.name ? "," : ""}
                  <span className="text-[12px] leading-none" > {anniversary?.department.name} {anniversary?.department?.name? "Department" :""} </span>
                  </span>
                  
                    <span className="text-[16px] font-semibold"> {moment(anniversary?.joining_date).format("Do MMMM")}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpComingAnnivesary;
