import React from "react";
import AsyncSelect from "react-select/async";

const AsyncMultiSelect = ({
  onInputChange = () => {},
  onchange = () => {},
  value,
}) => {
  return (
    <AsyncSelect
      value={value}
      isMulti
      cacheOptions
      defaultOptions
      onChange={onchange}
      loadOptions={onInputChange}
    />
  );
};

export default AsyncMultiSelect;
