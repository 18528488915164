import { Link, useNavigate } from "react-router-dom";
import H2 from "../../../components/headings/H2";
import ComponentHeader from "../common/ComponentHeader";
import { FaFilter, FaPlusSquare } from "react-icons/fa";
import DataTable from "../common/DataTable";
import { useEffect, useRef, useState } from "react";
import { getAdminUrl, getUploadsUrl } from "../../../ServiceConfig";
import $ from "jquery";
import InputLabel from "../inputs/InputLabel";
import ToggleInput from "../inputs/ToggleInput";

function ListingSeoList() {
  const listingSeoTable = useRef();
  const [selectedFilter, setSelectedFilter] = useState([]);
  const filterRef1 = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const navigate = useNavigate();

  const actionIcons = function (cell, formatterParams, onRendered) {
    return `
  <div class="flex items-center gap-3">
      <div class="listing-edit-btn cursor-pointer" data-id=${
        cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>

  </div>
  `;
  };

  const countCharactersWithoutHtmlTags = function (htmlString) {
    // Remove all HTML tags using a regular expression

    const text = htmlString || "";

    // Return the length of the resulting text
    return text?.length ? text?.length : 0;
  };

  const getDescriptionDetailsFormatter = (
    cell,
    formatterParams,
    onRendered
  ) => {
    let row = cell.getRow().getData();
    return `<div class="flex items-center flex-wrap">
      <ul class="list-disc text-left">
      <!--Short Description -->
      <li class="${
        countCharactersWithoutHtmlTags(row.short_description) > 0
          ? "text-green-600"
          : "text-red-600"
      }">Short Description : <b>${countCharactersWithoutHtmlTags(
      row.short_description
    )} Char.</b></li>
    <!--Description -->
      <li class="${
        countCharactersWithoutHtmlTags(row.description) > 0
          ? "text-green-600"
          : "text-red-600"
      }">Description : <b>${
      countCharactersWithoutHtmlTags(row.description) || 0
    } Char.</b></li>
    <!--More Description -->
      <li class="${
        row.more_description > 0 ? "text-green-600" : "text-red-600"
      }">More Description : <b>${
      countCharactersWithoutHtmlTags(row.more_description) || 0
    } Char.</b></li>
    <!--FAQs -->
      <li class="${
        row.faqs.length > 0 ? "text-green-600" : "text-red-600"
      }">FAQs : <b>${row.faqs.length} Nos.</b></li>
    
      
      </ul>
     
    </div>`;
  };
  const getSeoDetailsFormatter = (cell, formatterParams, onRendered) => {
    let row = cell.getRow().getData();
    
    return `<div class="flex items-center flex-wrap">
      <ul class="list-disc text-left">
      <!--Short Description -->
      <li class="${
        countCharactersWithoutHtmlTags(row?.meta_title) > 0
          ? "text-green-600"
          : "text-red-600"
      }">Meta Title : <b>${countCharactersWithoutHtmlTags(
      row?.meta_title
    )} Char.</b></li>
    <!--Description -->
      <li class="${
        countCharactersWithoutHtmlTags(row?.meta_description) > 0
          ? "text-green-600"
          : "text-red-600"
      }">Meta Description : <b>${
      countCharactersWithoutHtmlTags(row?.meta_description) || 0
    } Char.</b></li>
    <!--Meta Keywords -->
      <li class="${
        row?.meta_keywords?.split(",")?.length > 0
          ? "text-green-600"
          : "text-red-600"
      }">Meta Keywords : <b>${
      row?.meta_keywords?.split(",")?.length || 0
    } Keywords.</b></li>
    <!--Meta Keywords -->
      <li class="${
        row?.meta_image ? "text-green-600" : "text-red-600"
      }">Meta Image Uploaded ? : <b>${row?.meta_image ? "Yes" : "No"}.</b></li>
    
      
      </ul>
     
    </div>`;
  };
  const getRelatedResourceFormatter = (cell, formatterParams, onRendered) => {
    let row = cell.getRow().getData();
    return `<div class="flex items-center flex-wrap">
      <ul class="list-disc text-left">
      <!--Youtube -->
      <li class="${
        row.youtube_videos.length >= 5 ? "text-green-600" : "text-red-600"
      }">Youtube Videos : <b>${row.youtube_videos.length} </b></li>
    
      <!--Instagram  -->
      <li class="${
        row.insta_carousel.length >= 3 ? "text-green-600" : "text-red-600"
      }">Instagram Carousels : <b>${row.insta_carousel.length} </b></li>
    
      <!--Blogs -->
      <li class="${
        row.blog_ids.length == 4 ? "text-green-600" : "text-red-600"
      }">Blogs : <b>${row.blog_ids.length} </b></li>
    
      <!--Youtube -->
     
      <li class="text-blue-600">Reviews  : <b> Coming Soon... </b></li>
    
      </ul>
     
    </div>`;
  };

  const bannerImages = function (cell, formatterParams, onRendered) {
    var square = 0;
    var landscape = 0;
    var mobile = 0;

    cell
      .getRow()
      .getData()
      .images?.map((banner) => {
        if (
          banner.banner_type === "desktop_square-1" ||
          banner.banner_type === "desktop_square-2"
        ) {
          square++;
        }
        if (
          banner.banner_type === "desktop_landscape-1" ||
          banner.banner_type === "desktop_landscape-2"
        ) {
          landscape++;
        }
        if (banner.banner_type === "mobile") {
          mobile++;
        }
      });

    return `<div class="flex items-center flex-wrap">
      <ul class="list-disc text-left">
      <li class="${
        landscape == 2 ? "text-green-600" : "text-red-600"
      }">Desktop - landscape(2) : <b>${landscape}</b></li>
      <li class="${
        square == 2 ? "text-green-600" : "text-red-600"
      }">Desktop - square(2) : <b>${square}</b></li>
      <li class="${
        mobile == 1 ? "text-green-600" : "text-red-600"
      }">Mobile(1) : <b >${mobile}</b></li>
      </ul>
     
    </div>`;
  };

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Title",
      field: "title",
      width: "260",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
      frozen: true,
    },
    {
      title: "Slug",
      field: "slug",
      resizable: true,
      vertAlign: "middle",
      formatter: "textarea",
      width: "260",
    },
    {
      title: "Banner Images",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      field: "images",
      width: "260",
      formatter: bannerImages,
    },
    {
      title: "Missing Images",
      hozAlign: "center",
      vertAlign: "middle",
      headerSort: false,
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getData().images.length >= 5) {
          return "No";
        } else {
          return "Yes";
        }
      },
      width: "160",
    },
    {
      title: "Text & Description",
      field: "id",
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      width: "360",
      formatter: getDescriptionDetailsFormatter,
    },
    {
      title: "SEO & Meta",
      field: "id",
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      width: "360",
      formatter: getSeoDetailsFormatter,
    },

    {
      title: "Related Resources Linking",
      field: "id",
      resizable: true,
      headerSort: false,
      hozAlign: "center",
      vertAlign: "middle",
      width: "360",
      formatter: getRelatedResourceFormatter,
    },
    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: true,
    },
  ];

  useEffect(() => {
    // polyfillCountryFlagEmojis();
    $(document).on("click", ".listing-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/listing-seo/edit/${cell}`);
    });

    return () => {
      $(document).off("click", ".listing-edit-btn");
    };
  }, []);

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        console.log("one test", selectedFilter);
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        console.log("two test", selectedFilter);
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        console.log("three test", selectedFilter);
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      console.log("four test", selectedFilter);
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  useEffect(() => {
    listingSeoTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Listing SEO</H2>
        <Link
          to="/listing-seo/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <div ref={filterRef1} className=" mr-1 inline-block pt-4 lg:mr-2 ">
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={`flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-2 py-2  text-xs font-semibold tracking-wide dark:bg-slate-800 lg:p-2 lg:text-lg ${
              filterOpen
                ? "-mb-[1px] rounded-b-none border-b-0 px-2 pb-4 pt-2 lg:px-8"
                : ""
            }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>
        <div
          className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-800 ${
            filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div className="min-w-[250px]">
            <InputLabel label="Title" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "title",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="min-w-[250px]">
            <InputLabel label="Slug" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "slug",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>

          <div className="min-w-[100px]">
            <InputLabel label="Are Images Missing?" />
            <ToggleInput
              checked={
                selectedFilter.find(
                  (filter) => filter.field === "is_missing_image"
                )?.value
                  ? 1
                  : 0
              }
              onToggle={(e) => {
                updateFilter({
                  field: "is_missing_image",
                  type: "=",
                  value: e.target.checked ? 1 : 0,
                });
              }}
            />
          </div>
        </div>
        <div className="package-table h-full">
          <DataTable
            ref={listingSeoTable}
            ajaxUrl={`${getAdminUrl()}/get-allSeos`}
            columns={columns}
            // search={true}
            // responsiveLayout="collapse"
          />
        </div>
      </div>
    </div>
  );
}

export default ListingSeoList;
