import { admin } from "../../ServiceConfig";

export function getListingSeoById(id) {
  return admin.get(`/seo/${id}`);
}
//corrected spelling
export function createListingSeo(data) {
  return admin.post(`/seo`, data);
}

export function updateListingSeoData(id, data) {
  return admin.put(`/update/seo/${id}`, data);
}

export function uploadListingSquareImage(file) {
  return admin.post(`uploads/image?upload_path=listing-seo/square/`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadListingLandscapeImage(file) {
  return admin.post(`uploads/image?upload_path=listing-seo/landscape/`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadMetaImage(file) {
  return admin.post(`uploads/image?upload_path=listing-seo/meta-image`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getGoogleReviewsByText(data){
  return admin.post(`google-reviews-search`, data)
}

export function getGoogleReviewById(id){
  return admin.get(`/google-reviews/${id}`)
}
