import moment from "moment/moment";
import StatWidgetWrapper from "../../widgets/StatWidgetWrapper";
import LeadByStatus from "../../widgets/leads/LeadByStatus";
import AnnualSale from "../../widgets/sales/AnnualSale";
import EmployeeSalesLeaderboard from "../../widgets/sales/EmployeeSalesLeaderboard";
import OrgChart from "../../widgets/sales/Orgchart";
import SalesByPaxLeaderboard from "../../widgets/sales/SalesByPaxLeaderboard";
import SalesByPaxLeaderboardLastFY from "../../widgets/sales/SalesByPaxLeaderboardLastFY";
import SalesByRevenueLeaderboard from "../../widgets/sales/SalesByRevenueLeaderboard";
import SalesByRevenueLeaderboardLastFY from "../../widgets/sales/SalesByRevenueLeaderboardLastFY";
import CurrentlyHiring from "./CurrentlyHiring";
import DeparturesUpcoming from "./DeparturesUpcoming";
import ImportantLinks from "./ImportantLinks";
import InternalNotifications from "./InternalNotifications";
import RewardsAndRecognition from "./RewardsAndRecognition";
import UpcomingBirthday from "./UpcomingBirthday";
import TodayBirthDay from "./TodayBirthDay";
import UpComingAnnivesary from "./UpComingAnniversary";

function DashboardWrapper() {
  const array = [1, 2, 3, 4, 5, 6];
  return (
    <div div className="">
      <StatWidgetWrapper />

      <AnnualSale />
      {/* <OrgChart />         */}

      <div className="flex h-full flex-wrap overflow-auto rounded-lg bg-transparent p-2 dark:bg-slate-600">
        <div className="grid h-full w-full grid-cols-2 gap-8 rounded-lg md:grid-cols-4">
          {/* <div className="max-w-[33%] basis-1/3 p-2">
          <LeadByStatus />
        </div> */}
          {/* <div className="col-span-2 md:col-span-4">
            <StatWidgetWrapper />
          </div> */}
          {/* <TodayBirthDay /> */}
          <div className="col-span-2 md:col-span-2">
            <UpcomingBirthday />
          </div>
          <div className="col-span-2 md:col-span-2">
            <UpComingAnnivesary />
          </div>

          <div className="col-span-2 md:col-span-2">
            <ImportantLinks />
          </div>
          <div className="col-span-2 md:col-span-2">
            <RewardsAndRecognition />
          </div>
          <div className="col-span-2 md:col-span-2">
            <SalesByRevenueLeaderboardLastFY />
          </div>
          <div className="col-span-2 md:col-span-2">
            <SalesByPaxLeaderboardLastFY />
          </div>
          <div className="col-span-2 md:col-span-2">
            <SalesByRevenueLeaderboard />
          </div>
          <div className="col-span-2 md:col-span-2">
            <SalesByPaxLeaderboard />
          </div>
          <div className="col-span-2 md:col-span-2">
            <EmployeeSalesLeaderboard />
          </div>
          <div className="col-span-2 md:col-span-2">
            <InternalNotifications />
          </div>
          <div className="col-span-2 md:col-span-2">
            <DeparturesUpcoming />
          </div>
          <div className="col-span-2 md:col-span-2">
            <CurrentlyHiring />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardWrapper;
