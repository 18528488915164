import Select from "react-select";

function MultiSelect({ options, value, onChange, onInputChange = () => {} }) {
  return (
    <div className="w-full rounded-md !border">
      <Select
        autoFocus
        value={
          value?.length > 0
            ? value?.map((v) => ({
                value: v,
                label: options.filter((opt) => opt.value === parseInt(v))[0]
                  ?.label,
              }))
            : []
        }
        isMulti
        options={options ? [{ label: "None", value: "" }, ...options] : []}
        onChange={(value) => onChange(value.map((v) => v.value))}
        classNamePrefix={"react-select"}
        onInputChange={(value) => onInputChange(value)}
      ></Select>
    </div>
  );
}

export default MultiSelect;
