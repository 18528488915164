import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputLabel from "../inputs/InputLabel";
import {
  createListingSeo,
  getGoogleReviewsByText,
  updateListingSeoData,
  uploadMetaImage,
} from "../../services/ListingSeoServices";
import { getAllBlogs } from "../../services/PackageService";
import { toast } from "react-toastify";
import MultiSelect from "../inputs/MultiSelect";
import EditorInput from "../inputs/EditorInput";
import KeywordsInput from "../inputs/KeywordsInput";
import { MdClose } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";
import FileInput from "../../../components/inputFields/FileInput";
import { getUploadsUrl } from "../../../ServiceConfig";
import * as Yup from "yup";
import ReviewCard from "./ReviewCard";
import AsyncMultiSelect from "../inputs/AsyncMultiSelect";

const ListingSeoFormSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required"),
  slug: Yup.string().required("Slug is Required"),
  // reviewIds: Yup.array()
  //   .of(Yup.string().required("Each review ID must be a string"))
  //   .min(3, "At least 3 review IDs are required")
  //   .max(10, "A maximum of 10 review IDs are allowed"),
});

const FormStep1 = ({ onSuccess, formData, edit, handleNextForm }) => {
  const [blogs, setBlogs] = useState([]);

  const getShowingReviewsByText = (givenText) => {
    return new Promise((resolve, reject) => {
      if (givenText) {
        getGoogleReviewsByText({ review_text: givenText })
          .then((res) => {
            const temp = res.data.data.map((onerev) => ({
              label: <ReviewCard data={onerev} />,
              value: onerev.id,
            }));
            resolve(temp);
          })
          .catch((error) => {
            console.error("Error in getting Google reviews", error);
            reject(error);
          });
      } else {
        resolve([]);
      }
    });
  };

  const addListingSeo = (values) => {
    createListingSeo(values)
      .then((res) => {
        toast.success(res.data.message);
        handleNextForm(
          res.data.data.id,
          res.data.data.title,
          res.data.data.slug
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const editListingSeo = (values) => {
    updateListingSeoData(formData.id, values)
      .then((res) => {
        toast.success(res.data.message);
        handleNextForm(
          res.data.data.id,
          res.data.data.title,
          res.data.data.slug
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAllBlogs().then((res) => {
      setBlogs(res.data);
    });
  }, []);

  return (
    <div className="max-w-full">
      <Formik
        enableReinitialize
        initialValues={{
          banner_image_square: formData?.banner_image_square || [],
          banner_image_landscape: formData?.banner_image_landscape || [],
          title: formData?.title || "",
          slug: formData?.slug || "",
          short_description: formData?.short_description || "",
          description: formData?.description || "",
          blog_ids: formData?.blog_ids || [],
          youtube_videos: formData?.youtube_videos || [],
          insta_carousel: formData?.insta_carousel || [],
          more_description: formData?.more_description || "",
          meta_title: formData?.meta_title || "",
          meta_description: formData?.meta_description || "",
          meta_keywords: formData?.meta_keywords || "",
          meta_image: formData?.meta_image || "",
          faqs: formData?.faqs || [],
          "desktop_landscape-1": formData?.["desktop_landscape-1"] || "",
          "desktop_landscape-2": formData?.["desktop_landscape-2"] || "",
          "desktop_square-1": formData?.["desktop_square-1"] || "",
          "desktop_square-2": formData?.["desktop_square-2"] || "",
          mobile: formData?.mobile || "",
          google_review_ids: formData?.google_review_ids
            ? formData?.google_review_ids.map((onerev) => ({
                key: onerev,
                value: onerev,
                label: <ReviewCard key={onerev} id={onerev} />,
              }))
            : [],
        }}
        validationSchema={ListingSeoFormSchema}
        onSubmit={(values, { isSubmitting }) => {
          values.google_review_ids = values.google_review_ids.map((onerev) =>
            onerev.value ? onerev.value : onerev
          );
          edit
            ? editListingSeo(values, isSubmitting)
            : addListingSeo(values, isSubmitting);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <InputLabel label="Title" labelFor="title" required={true} />
                <Field className="field w-full" id="title" name="title" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="error-msg"
                />
              </div>

              <div>
                <InputLabel label="Slug" labelFor="slug" required={true} />
                <Field className="field w-full" id="slug" name="slug" />
                <ErrorMessage
                  name="slug"
                  component="div"
                  className="error-msg"
                />
              </div>
            </div>

            <div className="my-6">
              <InputLabel
                label="Short Description"
                labelFor="short_description"
              />
              <EditorInput
                fieldKey="short_description"
                data={values.short_description}
              />
              <ErrorMessage
                name="short_description"
                component="div"
                className="error-msg"
              />
            </div>

            <div className="my-6">
              <InputLabel label="Description" labelFor="description" />
              <EditorInput fieldKey="description" data={values.description} />
              <ErrorMessage
                name="description"
                component="div"
                className="error-msg"
              />
            </div>

            <div className="my-6">
              <InputLabel label="Related Blogs" />
              <MultiSelect
                onChange={(value) => setFieldValue("blog_ids", value)}
                value={Array.isArray(values.blog_ids) && values.blog_ids}
                options={blogs?.map((blog) => ({
                  label: blog?.title,
                  value: blog.id,
                }))}
              />
              <ErrorMessage
                name="blog_ids"
                component="div"
                className="error-msg"
              />
            </div>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div>
                <InputLabel label="Youtube Video Ids" />
                <KeywordsInput
                  value={
                    Array.isArray(values.youtube_videos) &&
                    values.youtube_videos?.map((v) => ({
                      label: v,
                      value: v,
                    }))
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "youtube_videos",
                      value.map((v) => v.value)
                    )
                  }
                />
              </div>

              <div>
                <InputLabel label="Insta Post Ids" />
                <KeywordsInput
                  value={
                    Array.isArray(values.insta_carousel) &&
                    values.insta_carousel?.map((v) => ({
                      label: v,
                      value: v,
                    }))
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "insta_carousel",
                      value.map((v) => v.value)
                    )
                  }
                />
              </div>
            </div>

            <div className="my-6">
              <InputLabel
                label="More Description"
                labelFor="more_description"
              />
              <EditorInput
                fieldKey="more_description"
                data={values.more_description}
              />
            </div>

            <div className="my-6">
              <FieldArray name="faqs">
                {({ insert, remove, push }) => (
                  <div className="relative rounded-lg border-2 p-4">
                    <h5 className="mb-2 text-lg font-medium">Faqs</h5>
                    {values.faqs.map((faq, indx) => (
                      <div key={indx} className="my-4">
                        <div className="flex items-center gap-4">
                          <div className="flex-grow">
                            <InputLabel label="Question" />
                            <Field
                              name={`faqs.${indx}.question`}
                              type="text"
                              className="field w-full"
                            />
                            <ErrorMessage
                              name={`faqs.${indx}.question`}
                              component="div"
                              className="error-msg"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => remove(indx)}
                            className="rounded-full border p-1"
                          >
                            <MdClose />
                          </button>
                        </div>
                        <div className="my-4">
                          <InputLabel label="Answer" />
                          <EditorInput
                            fieldKey={`faqs.${indx}.answer`}
                            data={values.faqs[indx].answer}
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="my-4 flex items-center gap-2 rounded border border-green-500 px-4 py-2 text-green-500"
                      onClick={() => push({ question: "", answer: "" })}
                    >
                      <FaPlusSquare /> Add FAQ
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className="my-6">
              <InputLabel
                label="Reviews"
                labelFor="google_review_ids"
                instruction="(Select 3 at least or maximum 10)"
              />
              <AsyncMultiSelect
                value={values.google_review_ids}
                onInputChange={getShowingReviewsByText}
                onchange={(e) => {
                  setFieldValue("google_review_ids", e);
                }}
              />
              <ErrorMessage
                name="google_review_ids"
                component="div"
                className="error-msg"
              />
            </div>

            <div className="my-6 rounded-lg border p-4">
              <h3 className="mb-2 text-lg font-bold">Meta Info</h3>

              <div className="my-4">
                <InputLabel
                  label="Meta Title"
                  labelFor="meta_title"
                  instruction="(Max 70 Characters)"
                />
                <Field
                  className="field w-full"
                  id="meta_title"
                  name="meta_title"
                />
                <ErrorMessage
                  name="meta_title"
                  component="div"
                  className="error-msg"
                />
              </div>

              <div className="my-4">
                <InputLabel
                  label="Meta Description"
                  labelFor="meta_description"
                  instruction="(Max 160 Characters)"
                />
                <Field
                  as="textarea"
                  name="meta_description"
                  className="field w-full"
                />
                <ErrorMessage
                  name="meta_description"
                  component="div"
                  className="error-msg"
                />
              </div>

              <div className="my-4">
                <InputLabel label="Meta Keywords" />
                <KeywordsInput
                  value={
                    values?.meta_keywords
                      ? values?.meta_keywords?.split(",")?.map((v) => ({
                          label: v,
                          value: v,
                        }))
                      : []
                  }
                  onChange={(value) =>
                    setFieldValue(
                      "meta_keywords",
                      value.map((v) => v.value).toString()
                    )
                  }
                />
              </div>

              <div className="my-4">
                <InputLabel label="Meta Image" />
                <FileInput
                  value={`${getUploadsUrl()}/listing-seo/meta-image/${
                    values.meta_image
                  }`}
                  noOfFilesAllowed={1}
                  onChange={(file) => {
                    uploadMetaImage({ image: file }).then((res) => {
                      setFieldValue(
                        "meta_image",
                        res.data.data[0].uploaded_image
                      );
                    });
                  }}
                />
                <ErrorMessage
                  name="meta_image"
                  component="div"
                  className="error-msg"
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn-primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormStep1;
