import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $, { type } from "jquery";
import { toast } from "react-toastify";
import DataTable from "../components/common/DataTable";
import { deleteState } from "../services/StateServices";
import { getAdminUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { FaFilter, FaPlusSquare, FaTrash } from "react-icons/fa";
import { getReadableDate } from "../../helpers/Helper";
import ToggleInput from "../components/inputs/ToggleInput";
import { deleteVerificationData } from "../services/BookingServices";
import InputLabel from "../components/inputs/InputLabel";
import DateRangeInput from "../components/inputs/DateRangeInput";
import SelectSearch from "../components/inputs/SelectSearch";
import Swal from "sweetalert2";
import { getAllEmployeesOption } from "../services/EmployeeServices";

const statusArray = [
  {
    value: "0",
    label: "User Update Awaited",
  },
  {
    value: "1",
    label: "Waiting for Approval",
  },
  {
    value: "2",
    label: "Rejected",
  },
  {
    value: "3",
    label: "Verified",
  },
];

function VerificationLinks() {
  const navigate = useNavigate();
  const bookingTable = useRef();
  const [onlyCustomized, setOnlyCustomized] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const filterRef1 = useRef(null);
  const [bookingStatusFilter, setBookingStatusFilter] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [seeAllFilters, setSeeAllFilters] = useState(false);

  const deleteSelectedVerificationLink = (id) => {
    Swal.fire({
      title: "Do you want to Delete this verification link?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#c70000",
      cancelButtonColor: "#898989",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteVerificationData(id)
          .then((res) => {
            bookingTable.current.deleteRow(id);
            toast.success("Booking Verification Link Deleted.");
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      } else {
        toast.info("Booking Verification Link Deletion cancelled.");
      }
    });
  };

  function cellClick(cell) {
    console.log(cell.getData().encoded_id, "cell clicked");

    if (cell.getColumn().getDefinition().title === "URL") {
      let copyText = `justwravel.com/booking/payment-verification/${cell.getData().encoded_id
        }`;
      navigator.clipboard.writeText(copyText);
      toast.success("Link Copied.");
    }
  }

  const rowFormatter = (row) => {
    if (row.getData().is_customized === 1) {
      const children = row.getElement().childNodes;
      children.forEach((child) => {
        child.style.color = "#9E6F21";
      });
    }
  };

  const isFrozen = window.innerWidth <= 768 ? false : true;

  const actionIcons = function (cell, formatterParams, onRendered) {
    if (cell.getRow().getData().status == 0) {
      return `<div class="verification-view-btn" data-id=${cell.getRow().getData().id
        } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="30" height="30">
<path d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"></path>
</svg></div>
    <div class="verification-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
        }>
        <i class="fas fa-trash-alt"></i>
    </div>
   `;
    } else {
      return `<div class="verification-view-btn" data-id=${cell.getRow().getData().id
        } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="30" height="30">
<path d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"></path>
</svg></div>
    
   `;
    }
  };

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Package",
      field: "package_name",
      minWidth: "200",
      vertAlign: "middle",
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
      formatter: "textarea",
    },
    {
      title: "Event Date",
      minWidth: "120",
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return getReadableDate(cell.getValue());
      },
    },
    {
      title: "Traveller",

      minWidth: "200",
      field: "first_name",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${cell.getRow().getData().last_name
          } <br/> ${cell.getRow().getData().email} <br/> ${cell.getRow().getData().phone
          }`;
      },
    },
    {
      title: "Pax",
      field: "total_pax",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "70",
    },
    {
      title: "URL",
      field: "encoded_id",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        return `<p class="bg-blue-600 font-semibold border-2 rounded-lg border-blue-600 p-2 text-white">Copy Link</p>`;
      },
    },
    {
      title: "Whatsapp",
      field: "encoded_id",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120",
      formatter: function (cell, formatterParams, onRendered) {
        let phone = cell.getRow().getData().phone;
        let country_code = cell.getRow().getData().country_code ?? 91;
        let msg = `Please review this form with the link and upload the screenshot of the payment along with the transaction ID. https://www.justwravel.com/booking/payment-verification/${cell.getData().encoded_id
          }`;
        return `<a href="https://api.whatsapp.com/send?phone=${country_code}${phone}&text=${msg}" class="bg-green-600 text-white font-semibold border-2 rounded-lg border-green-600 p-2">Whatsapp</a>`;
      },
    },
    {
      title: "Status",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "220",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().status === 0) {
          cell.formatter = "textarea";
          return `<p class="border-2 rounded-lg border-orange-600 p-2 text-orange-600">User Update Awaited</p>`;
        }
        if (cell.getRow().getData().status === 1)
          return `<p class="border-2 rounded-lg border-cyan-600 p-2 text-cyan-600">Waiting for Approval</p>`;
        if (cell.getRow().getData().status === 2)
          return `<p class="border-2 rounded-lg border-red-600 p-2 text-red-600">Rejected</p>`;
        if (cell.getRow().getData().status === 3)
          return `<p class="border-2 rounded-lg border-green-600 p-2 text-green-600">Verified</p>`;
      },
    },
    {
      title: "Amount",
      field: "amount",
      resizable: true,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120",
    },
    {
      title: "Amount Paid",
      field: "amount_paid",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120",
    },
    {
      title: "Converted By",
      field: "converted_by",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      minWidth: "120",
    },
    {
      formatter: function (cell, formatterParams, onRendered) {
        return `<div class="verification-view-btn" data-id=${cell.getRow().getData().id
          } style="display: flex; align-items: center; gap: 12px;">
    <div style="cursor: pointer; title="Edit">
    <svg width="30" height="30">
<path d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"></path>
</svg></div>
    
   `;
      },
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
    {
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().status == 0) {
          return `
    <div class="verification-delete-btn cursor-pointer text-red-500" data-id=${cell.getRow().getData().id
            }>
        <i class="fas fa-trash-alt"></i>
    </div>
   `;
        } else {
          return `
    
   `;
        }
      },
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
      frozen: isFrozen,
    },
  ];

  useEffect(() => {
    $(document).on("click", ".verification-view-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      window.open(`/bookings/verification-links/${cell}`, "_blank");
    });
    $(document).on("click", ".verification-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      deleteSelectedVerificationLink(cell);
      // navigate(`/bookings/verification-links/${cell}`);
    });

    return () => {
      $(document).off("click", ".verification-view-btn");
      $(document).off("click", ".verification-delete-btn");
    };
  }, []);

  const updateFilter = (filter) => {
    let index = selectedFilter.findIndex((sf) => sf.field === filter.field);

    if (index >= 0) {
      if (filter.value && !Array.isArray(filter.value)) {
        console.log("one test", selectedFilter);
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else if (Array.isArray(filter.value) && filter.value[0]) {
        console.log("two test", selectedFilter);
        let appliedFilter = [...selectedFilter];
        appliedFilter[index].value = filter.value;
        setSelectedFilter(appliedFilter);
      } else {
        console.log("three test", selectedFilter);
        setSelectedFilter(
          selectedFilter.filter((af) => af.field !== filter.field)
        );
      }
    } else {
      console.log("four test", selectedFilter);
      setSelectedFilter([...selectedFilter, filter]);
    }
  };
  const [employees, setEmployees] = useState();
  useEffect(() => {
    getAllEmployeesOption()
      .then((res) => {
        setEmployees(res.data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    bookingTable.current.setFilter(selectedFilter);
  }, [selectedFilter]);

  return (
    <div className="flex flex-col">
      <ComponentHeader className="border-b">
        <div className="flex flex-wrap items-center gap-4">
          <H2>Payment Verification Links</H2>
          <div className="hidden items-center gap-1 lg:flex">
            <ToggleInput
              checked={onlyCustomized}
              onToggle={(e) => {
                setOnlyCustomized(e.target.checked);
                updateFilter({
                  field: "is_customized",
                  type: "=",
                  value: e.target.checked ? 1 : 0,
                });
              }}
            />
            <span className="font-medium">Only Customized</span>
          </div>
        </div>
        <Link
          to="/bookings/verification-links/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>

      <div className="my-2 flex items-center gap-1 lg:hidden">
        <ToggleInput
          checked={onlyCustomized}
          onToggle={(e) => {
            setOnlyCustomized(e.target.checked);
            updateFilter({
              field: "is_customized",
              type: "=",
              value: e.target.checked ? 1 : 0,
            });
          }}
        />
        <span className="font-medium">Only Customized</span>
      </div>

      <div className="m-2 flex flex-col">
        {/* <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#D83F31]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#D83F31]">
            Cancelled
          </p>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#3876BF]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#3876BF]">
            Customized Boking
          </p>
        </div> */}
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-[#9E6F21]"></div>
          <p className="rounded-full px-1 text-[0.625rem] font-medium leading-3 text-[#9E6F21]">
            Customized
          </p>
        </div>
        <div
          ref={filterRef1}
          // onClick={() => setFilterOpen(!filterOpen)}
          className=" mr-1 inline-block pt-4 lg:mr-2 "
        >
          <button
            onClick={() => setFilterOpen(!filterOpen)}
            className={`flex items-center gap-1 whitespace-nowrap rounded-md border bg-white p-2 py-2  text-xs font-semibold tracking-wide dark:bg-slate-800 lg:p-2 lg:text-lg ${filterOpen
              ? "-mb-[1px] rounded-b-none border-b-0 px-2 pb-4 pt-2 lg:px-8"
              : ""
              }`}
          >
            <FaFilter />
            Filters
          </button>
        </div>

        <div
          className={`flex grow flex-wrap  items-center gap-4 rounded-md rounded-tl-none bg-white dark:bg-slate-800 ${filterOpen ? "mb-4 border p-4" : "h-0 overflow-hidden p-0"
            }`}
        >
          {seeAllFilters && (
            <div className="min-w-[250px]">
              <InputLabel label="Converted By" />
              <SelectSearch
                value={
                  selectedFilter.find(
                    (filter) => filter.field === "converted_by"
                  )?.value
                }
                options={employees?.map((emp) => ({
                  label: `${emp?.first_name} ${emp?.last_name}`,
                  value: emp?.id,
                }))}
                onChange={(value) =>
                  updateFilter({
                    field: "converted_by",
                    type: "=",
                    value: value,
                  })
                }
              />
            </div>
          )}
          {seeAllFilters && (

            <div className="min-w-[250px]">
              <InputLabel label="Created At" />
              <DateRangeInput
                controlBtns={true}
                horizontalPosition="left"
                onRangeChange={(startDate, endDate) =>
                  updateFilter({
                    field: "created_at",
                    type: "in",
                    value: [
                      startDate
                        ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                        }-${new Date(startDate).getDate()}`
                        : "",
                      endDate
                        ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                        }-${new Date(endDate).getDate()}`
                        : "",
                    ],
                  })
                }
              />
            </div>
          )}
          <div className="min-w-[250px]">
            <InputLabel label="Event Date" />
            <DateRangeInput
              controlBtns={true}
              horizontalPosition="left"
              onRangeChange={(startDate, endDate) =>
                updateFilter({
                  field: "event_date",
                  type: "in",
                  value: [
                    startDate
                      ? `${new Date(startDate).getFullYear()}-${new Date(startDate).getMonth() + 1
                      }-${new Date(startDate).getDate()}`
                      : "",
                    endDate
                      ? `${new Date(endDate).getFullYear()}-${new Date(endDate).getMonth() + 1
                      }-${new Date(endDate).getDate()}`
                      : "",
                  ],
                })
              }
            />
          </div>

          <div>
            <InputLabel label="First Name" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "first_name",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          {seeAllFilters && (
            <div>
              <InputLabel label="Last Name" />
              <input
                type="text"
                className="field"
                onChange={(e) =>
                  updateFilter({
                    field: "last_name",
                    type: "like",
                    value: e.target.value,
                  })
                }
              />
            </div>
          )}
          <div>
            <InputLabel label="Phone" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "phone",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Email" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "email",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputLabel label="Package Name" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "package_name",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div>
          {/* <div>
            <InputLabel label="Package Id" />
            <input
              type="text"
              className="field"
              onChange={(e) =>
                updateFilter({
                  field: "package_id",
                  type: "like",
                  value: e.target.value,
                })
              }
            />
          </div> */}
          <div className="min-w-[250px]">
            <InputLabel label="Status" />
            <SelectSearch
              options={statusArray}
              value={bookingStatusFilter}
              onChange={(value) => {
                setBookingStatusFilter(value);
                updateFilter({
                  field: "booking_status",
                  type: "=",
                  value: value,
                });
              }}
            />
          </div>
          {seeAllFilters ? (
            <div
              className="cursor-pointer self-end text-sm text-blue-700"
              onClick={() => setSeeAllFilters(false)}
            >
              see less filters .....
            </div>
          ) : (
            <div
              className="cursor-pointer self-end text-sm text-blue-500"
              onClick={() => setSeeAllFilters(true)}
            >
              see all filters .....
            </div>
          )}
        </div>
      </div>

      <div className="h-full">
        <DataTable
          ref={bookingTable}
          ajaxUrl={`${getAdminUrl()}/booking/verification/link`}
          columns={columns}
          // search={true}
          cellClick={cellClick}
          layout="fitColumns"
          initialSort={[{ column: "id", dir: "desc" }]}
          rowFormatter={rowFormatter}
        />
      </div>
    </div>
  );
}

export default VerificationLinks;
