import axios from "axios";
import { useEffect, useState } from "react";
import { getUpcomingBirthdays } from "../../services/AdminServices";
import { getUploadsUrl } from "../../../ServiceConfig";
import moment from "moment";

function UpcomingBirthday() {
  const [upcomingbirthdays, setupcomingBirthdays] = useState();

  const getUpcomingBdays = async () => {
    const result = await getUpcomingBirthdays();
    if (result.data.status === "success") {
      setupcomingBirthdays(result.data.data);
    }
  };
  useEffect(() => {
    getUpcomingBdays();
  }, []);

  return (
    <div>
      <div className="flex flex-col rounded-lg border bg-white dark:border-slate-700">
        <div className="card-header-background relative flex items-center border-b bg-gray-100 p-4 dark:border-b dark:border-slate-700 dark:bg-slate-900 dark:text-white">
          <img
            src={`${getUploadsUrl()}/dashboard-icons/upcoming-birthdays.png`}
            className="absolute bottom-2 left-0 h-16 w-16"
            alt=""
          />
          <h3 className="ml-12 font-bold">Upcoming Birthdays</h3>
        </div>
        <div className="max-h-[50vh] overflow-auto px-2 dark:bg-gray-600">
          <div className="flex flex-col items-center justify-center gap-3 py-4">
            {upcomingbirthdays?.map((SingleBirthDay, index) => (
              <div
                key={SingleBirthDay.id}
                className={`flex w-full items-center justify-between gap-4 rounded-md bg-white p-2 shadow-[1px_1px_4px_rgba(0,0,0,0.15)] transition-all duration-300 ease-out hover:shadow-md dark:bg-slate-800 dark:text-white`}
              >
                <div className="h-12 w-12 shrink-0 rounded-full bg-gray-300">
                  <img
                    src={`${getUploadsUrl()}/employees/${
                      SingleBirthDay?.profile_pic || "png-transparent-user.png"
                    }`}
                    alt="profile pic"
                    className="h-12 w-12 rounded-full"
                  />
                </div>
                <div className="line-clamp-1 grow font-medium">
                  <p className="relative pr-1 flex items-center justify-between gap-1 text-sm text-gray-800 dark:text-white">
                    <span>
                      {" "}
                      {SingleBirthDay?.first_name +
                        "  " +
                        SingleBirthDay?.last_name}
                      <br />
                      <span className="text-[12px] leading-none">
                        {SingleBirthDay?.designation}
                      </span>
                      {SingleBirthDay?.designation &&
                      SingleBirthDay?.department?.name
                        ? ","
                        : ""}
                      <span className="text-[12px] leading-none">
                        {" "}
                        {SingleBirthDay?.department.name}{" "}
                        {SingleBirthDay?.department?.name ? "Department" : ""}{" "}
                      </span>
                    </span>

                    <span className="text-[16px] font-semibold">
                      {" "}
                      {moment(SingleBirthDay?.dob).format("Do MMMM")}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingBirthday;
